import ImageGallery from 'react-image-gallery';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import cs from '../../const.js';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

var format = require('string-format');
const URL = cs.BaseURL + '/order/activity/list';

function formatValue(value) {
    if (value && value.created_at)
        value.created_at = moment
            .unix(value.created_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY HH:mm');

    if (value && value.action_type == cs.OrderActivity_Created) {
        if (value.author_type == cs.Author_System) value.action_type = 'Khởi tạo hồ sơ tự động';
        else value.action_type = 'Khởi tạo hồ sơ';
    }
    if (value && value.action_type == cs.OrderActivity_AssignToAgent) {
        value.action_type = 'Phân công cho ' + value.action_note;
    }

    if (value && value.action_type == cs.OrderActivity_AgentAccept) {
        value.action_type = value.author_name + ' chấp nhận thực hiện';
    }
    if (value && value.action_type == cs.OrderActivity_AgentReject) {
        value.action_type = value.author_name + ' từ chối thực hiện';
    }

    if (value && value.action_type == cs.OrderActivity_AgentMakePlan) {
        value.action_type = value.author_name + ' lên lịch hẹn khác hàng';
    }

    if (value && value.action_type == cs.OrderActivity_Submit) {
        value.action_type = value.author_name + ' đẩy hồ sơ về Trung Tâm Phê Duyệt';
    }

    if (value && value.action_type == cs.OrderActivity_OPSupplementRequest) {
        value.action_type =
            'NV vận hành ' + (value.author_fullname ? value.author_fullname : value.author_name) + ' yêu cầu bổ sung hồ sơ';
    }

    if (value && value.action_type == cs.OrderActivity_OPApprove) {
        value.action_type = 'NV vận hành ' + (value.author_fullname ? value.author_fullname : value.author_name) + ' đã duyệt';
    }
    if (value && value.action_type == cs.OrderActivity_OPReject) {
        value.action_type =
            'NV vận hành ' +
            (value.author_fullname ? value.author_fullname : value.author_name) +
            ' đã từ chối. ' +
            (value.action_note ? '. Nội dụng: ' + value.action_note : '');
    }

    if (value && value.action_type == cs.OrderActivity_MerchantApprove) {
        value.action_type =
            (value.author_fullname ? value.author_fullname : value.author_name ? value.author_name : value.mc_code) +
            ' phê duyệt hồ sơ ';
    }
    if (value && value.action_type == cs.OrderActivity_MerchantReject) {
        value.action_type =
            (value.author_fullname ? value.author_fullname : value.author_name ? value.author_name : value.mc_code) +
            ' từ chối hồ sơ' +
            (value.action_note ? '. Nội dụng: ' + value.action_note : '');
    }

    if (value && value.action_type == cs.OrderActivity_MerchantSupplementRequest) {
        value.action_type =
            (value.author_fullname ? value.author_fullname : value.author_name ? value.author_name : value.mc_code) +
            ' yêu cầu bổ sung hồ sơ';
    }

    if (value && value.action_type == cs.OrderActivity_OPCanceled) {
        value.action_type =
            (value.author_fullname ? value.author_fullname : value.author_name ? value.author_name : "Vận hành") +
            ' đã hủy hồ sơ';
    }


    if (value && value.action_type == cs.OrderActivity_AgentCanceled) {
        value.action_type =
            (value.author_fullname ? value.author_fullname : value.author_name ? value.author_name : 'Nhân viên Field') +
            ' đã hủy hồ sơ';
    }

    return value;
}

class OrderActivities extends React.Component {
    constructor(props) {
        super(props);

        this.state = { activities: [], orderCode: this.props.orderCode };
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    queryString() {
        const { orderCode } = this.state;
        let queryString = `${URL}?order_code=${orderCode}`;
        return queryString;
    }

    loadData() {
        const queryString = this.queryString();
        if (queryString === this.lastQuery) {
            this.setState({ loading: false });
            return;
        }
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var activities = [];

                    for (var i = 0; i < data.data.length; i++) {
                        activities.push(formatValue(data.data[i]));
                    }

                    this.setState({ activities: activities, loading: false });
                } else {
                    this.setState({
                        rows: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    }

    render() {
        const { activities } = this.state;
        const { classes } = this.props;
        return (
            <List className={classes.root}>
                <ListItem
                    style={{
                        flexGrow: 1,
                        width: '100%',
                        display: 'block'
                    }}
                >
                    {activities.map(item => (
                        <div
                            style={{
                                width: '100%',
                                display: 'inline-block'
                            }}
                        >
                            <ListItemText primary={item.created_at} style={{ float: 'left', marginRight: '50px' }} />
                            <ListItemText
                                primary={item.action_type}
                                style={{
                                    alignContent: 'left'
                                }}
                            />
                            <Divider style={{ margin: '10px 0 10px 0' }} />
                        </div>
                    ))}
                </ListItem>
            </List>
        );
    }
}

const styles = theme => ({
    inner_container: {
        padding: 20
    }
});
export default withStyles(styles)(OrderActivities);
