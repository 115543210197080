import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ToastProvider } from 'react-toast-notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleIcon from '@material-ui/icons/People';

import cs from '../const.js';
import DailyDashboard from './reports/DailyDashboard';
import MonthlyDashboard from './reports/MonthlyDashboard';

// import AllOrders from './orders/AllOrders';
// import NewOrders from './orders/NewOrders';
// import AllocatedOrders from './orders/AllocatedOrders';
// import WaitingForApproveOrders from './orders/WaitingForApproveOrders';
// import WaitingMerchantOrders from './orders/WaitingMerchantOrders';
// import SupplementRequestOrders from './orders/SupplementRequestOrders';
// import RejectedOrders from './orders/RejectedOrders';
// import MerchantApprovalOrders from './orders/MerchantApprovalOrders';
// import CanceledOrders from './orders/CanceledOrders';

import OrderDetail from './order/OrderDetail';
// import CreateOrder from './order/CreateOrder';
// import ImportOrder from './order/ImportOrder';
// import AgentList from './agent/AgentList';
// import AgentLocation from './agent_location/AgentLocation';
// import AgentProduct from './agent_product/AgentProduct';
// import OPAllocationList from './op-allocation/OPAllocationList';

import ChangePassword from './auth/ChangePassword';
import WaitPrinting from './cards/waitPrinting';
import FinishPrinting from './cards/finishPrinting';
import DeliveryToHub from './cards/deliveryToHub';
import ReturnFromHub from './cards/returnFromHub';
import CustomerReceived from './cards/customerReceived';
import Activated from './cards/activated';
import ReceivedFromBank from './cards/receivedFromBank';
import OnDeliveryToAgent from './cards/onDeliveryToAgent';
import DeliveredToAgent from './cards/deliveredToAgent';
import ReturnFromAgent from './cards/returnFromAgent.js';
import CardCancelled from './cards/cardCancelled';
import SuggestAgents from './cards/agents/SuggestAgents.js';
import CardKPIList from './CardKPI/CardKPIList.js';

const URL = cs.BaseURL + '/user/detail';
const revokeTokenURL = cs.BaseURL + '/auth/logout';
const drawerWidth = 280;

const isCardHubAcc = () => {
    var user = localStorage.getItem(cs.System_Code + '-card-user');
    if (!user) return false;
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole == cs.Role_Card_Hub_User ||
        userrole == cs.Role_Card_Hub_Leader ||
        userrole == cs.Role_Card_Hub_Manager
    );
};
const isCardBankAcc = () => {
    var user = localStorage.getItem(cs.System_Code + '-card-user');
    if (!user) return false;
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole == cs.Role_Card_Bank_User ||
        userrole == cs.Role_Card_Bank_Leader ||
        userrole == cs.Role_Card_Bank_Manager
    );
};

const isCardHub = () => {
    var user = localStorage.getItem(cs.System_Code + '-card-user');
    if (!user) return false;
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Card_Hub_Leader
        || userrole === cs.Role_Card_Hub_Manager
    );
}

const mainListItems = (
    <div>
        <ListItem button component={Link} to="/report/daily">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    bar_chart
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Thống kê ngày" />
        </ListItem>

        <ListItem button component={Link} to="/report/monthly">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    bar_chart
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Thống kê tháng" />
        </ListItem>

        <Divider />

        {/* <ListItem button component={Link} to="/new">
            <ListItemIcon>
                <Icon
                    className="fa fa-file "
                    color="disable"
                    style={{ marginLeft: 12, fontSize: 23 }}
                />
            </ListItemIcon>
            <ListItemText primary="Chưa phân bổ" />
        </ListItem>

        <ListItem button component={Link} to="/allocated">
            <ListItemIcon>
                <Icon
                    className="fa fa-file "
                    color="disable"
                    style={{ marginLeft: 12, fontSize: 23 }}
                />
            </ListItemIcon>
            <ListItemText primary="Đang thực hiện" />
        </ListItem> */}

        <ListItem button component={Link} to="/waitPrinting">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    query_builder
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Thẻ đã phát hành, chờ in" />
        </ListItem>

        <ListItem button component={Link} to="/finishPrinting">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    update
                </Icon>
            </ListItemIcon>
            <ListItemText
                primary="Thẻ đã in và chờ Hub nhập kho"
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
            />
        </ListItem>

        <ListItem button component={Link} to="/deliveryToHub">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    check_circle
                </Icon>
            </ListItemIcon>
            <ListItemText
                primary="Thẻ đã nhập kho Hub, chờ trả cho khách hàng"
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
            />
        </ListItem>

        {/* <ListItem button component={Link} to="/receivedFromBank">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    check_circle
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Thẻ nhận từ Bank" />
        </ListItem> */}

        {(isCardHubAcc() || !isCardBankAcc()) && (
            <ListItem button component={Link} to="/onDeliveryToAgent">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        query_builder
                    </Icon>
                </ListItemIcon>
                <ListItemText
                    primary="Thẻ đã xuất kho và chuyển phát đến RM/DSA"
                    primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
            </ListItem>
        )}

        {(isCardHubAcc() || !isCardBankAcc()) && (
            <ListItem button component={Link} to="/deliveredToAgent">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        check_circle
                    </Icon>
                </ListItemIcon>
                <ListItemText
                    primary="Thẻ đã bàn giao cho RM/DSA"
                    primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
            </ListItem>
        )}

        {isCardBankAcc() || !isCardHubAcc() ? (
            <ListItem button component={Link} to="/returnFromHub">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        cancel
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Thẻ chuyển hoàn Bank" />
            </ListItem>
        ) : (
                <ListItem button component={Link} to="/returnFromAgent">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            cancel
                    </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Thẻ chuyển hoàn Hub" />
                </ListItem>
            )}

        {(isCardBankAcc() || !isCardHubAcc()) && (
            <ListItem button component={Link} to="/cardCancelled">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        cancel
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Thẻ đã hủy" />
            </ListItem>
        )}

        <Divider variant="middle" />
        <ListItem button component={Link} to="/customerReceived">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    query_builder
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Thẻ đã đến tay khách hàng" />
        </ListItem>

        <ListItem button component={Link} to="/activated">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    check_circle
                </Icon>
            </ListItemIcon>
            <ListItemText
                primary="Thẻ được kích hoạt, sẵn sàng hoạt động"
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
            />
        </ListItem>

        <Divider />
        <ListItem button component={Link} to="/all">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    search
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Tra cứu" />
        </ListItem>

        <Divider />
        {isCardHub() &&
            <ListItem button component={Link} to="/kpi">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        search
             </Icon>
                </ListItemIcon>
                <ListItemText primary="KPI" />
            </ListItem>

        }

        {/* {(isOPLeader() || isOPManager()) && (
            <ListItem button component={Link} to="/agent">
                <ListItemIcon>
                    <SupervisorAccountIcon
                        color="disable"
                        style={{ marginLeft: 5, fontSize: 28 }}
                    />
                </ListItemIcon>
                <ListItemText primary="Hồ sơ DSA" />
            </ListItem>
        )}

        {(isOPLeader() || isOPManager()) && (
            <ListItem button component={Link} to="/agent_location">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        settings
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Địa Bàn" />
            </ListItem>
        )}
        {(isOPLeader() || isOPManager()) && (
            <ListItem button component={Link} to="/agent_product">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        settings
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Sản Phẩm" />
            </ListItem>
        )}
        {isOPManager() && (
            <ListItem button component={Link} to="/op_allocation">
                <ListItemIcon>
                    <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                        settings
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Phân bổ cho OP" />
            </ListItem>
        )} */}
    </div>
);
const styles = (theme) => ({
    root: {
        display: 'flex',
        marginTop: 0,
        paddingTop: 10
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'rgb(68, 126, 236)'
    },
    toolbarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 2px',
        ...theme.mixins.toolbar
    },
    username: {
        marginRight: 1,
        // paddingLeft: 5,
        // paddingRight: 5,
        textAlign: 'right',
        fontWeight: 550,
        fontSize: 11
    },
    avatar: {
        marginRight: 6
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        height: '100vh',
        overflow: 'auto'
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    },
    logo: {
        margin: 20,
        width: 100
    },
    listItemText: {
        fontSize: '0.2rem'
    }
});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            open: true,
            showCreateOrder: false,
            showImportOrder: false,
            showChangePassword: false
        };

        this.handleShowCreateOrder = this.handleShowCreateOrder.bind(this);
        this.handleCloseCreateOrder = this.handleCloseCreateOrder.bind(this);
        this.handleShowImportOrder = this.handleShowImportOrder.bind(this);
        this.handleCloseImportOrder = this.handleCloseImportOrder.bind(this);
        this.handleShowChangePassword = this.handleShowChangePassword.bind(this);
        this.handleCloseChangePassword = this.handleCloseChangePassword.bind(this);
        this.postOneSignalPlayerIdToServer = this.postOneSignalPlayerIdToServer.bind(this);
        //this.loadData();
    }

    loadData() {
        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-card-user');
        if (user) username = JSON.parse(String(user)).name;

        if (!username) return;
        let queryString = `${URL}?name=${username}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        user: data.data,
                        loading: false
                    });
                } else {
                    this.setState({
                        user: {},
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    }
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    handleLogOut = () => {
        let queryString = `${revokeTokenURL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });

        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    handleShowImportOrder = () => {
        this.setState({ showImportOrder: true });
    };
    handleCloseImportOrder = () => {
        this.setState({ showImportOrder: false });
    };

    handleShowCreateOrder = () => {
        this.setState({ showCreateOrder: true });
    };
    handleCloseCreateOrder = () => {
        this.setState({ showCreateOrder: false });
    };

    handleShowChangePassword = () => {
        this.setState({ showChangePassword: true });
    };

    handleCloseChangePassword = () => {
        this.setState({ showChangePassword: false });
    };

    postOneSignalPlayerIdToServer() {
        console.log('Here!');
        var token = localStorage.getItem(cs.System_Code + '-card-token');
        if (!token || token == null || token == 'null' || token == undefined) return;

        var OneSignal = window.OneSignal || [];
        console.log(OneSignal);
        if (OneSignal.length !== 0) {
            OneSignal.getUserId(function (userId) {
                const URL = cs.BaseURL + '/notification/register_notify_device';
                fetch(URL, {
                    method: 'POST',
                    body: JSON.stringify({
                        onesignal_player_id: userId
                    }),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: token
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (
                            data &&
                            (data.code == cs.erAuthenticationFailed.code ||
                                data.code == cs.erAccountIsLocked.code)
                        ) {
                            sessionStorage.clear();
                            window.location.reload();
                        }
                    })
                    .catch(() => { });
            });
        }
    }

    render() {
        const { classes } = this.props;

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-card-user');
        if (user) username = JSON.parse(String(user)).fullname;
        if (!username) username = JSON.parse(String(user)).name;
        const DailyDashboardArea = () => (
            <div>
                <Typography component="div" className={classes.tableContainer}>
                    <DailyDashboard />
                </Typography>
            </div>
        );

        const MonthlyDashboardArea = () => (
            <div>
                <Typography component="div" className={classes.tableContainer}>
                    <MonthlyDashboard />
                </Typography>
            </div>
        );
        const WaitPrintingArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã phát hành, chờ in
                </Typography>
                <div className={classes.tableContainer}>
                    <WaitPrinting />
                </div>
            </div>
        );
        const FinishPrintingArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã in và chờ Hub nhập kho
                </Typography>
                <div className={classes.tableContainer}>
                    <FinishPrinting />
                </div>
            </div>
        );

        const DeliveryToHubsArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã nhập kho Hub, chờ trả cho khách hàng
                </Typography>
                <div className={classes.tableContainer}>
                    <DeliveryToHub />
                </div>
            </div>
        );

        const ReturnFromHubArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ chuyển hoàn Bank
                </Typography>
                <div className={classes.tableContainer}>
                    <ReturnFromHub />
                </div>
            </div>
        );

        const CustomerReceivedArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã đến tay khách hàng
                </Typography>
                <div className={classes.tableContainer}>
                    <CustomerReceived />
                </div>
            </div>
        );

        const ActivatedArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ được kích hoạt, sẵn sàng hoạt động
                </Typography>
                <div className={classes.tableContainer}>
                    <Activated />
                </div>
            </div>
        );

        const ReceivedFromBankArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ nhận từ bank
                </Typography>
                <div className={classes.tableContainer}>
                    <ReceivedFromBank />
                </div>
            </div>
        );

        const OnDeliveryToAgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã xuất kho và chuyển phát đến RM/DSA
                </Typography>
                <div className={classes.tableContainer}>
                    <OnDeliveryToAgent />
                </div>
            </div>
        );

        const DeliveredToAgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã bàn giao cho RM/DSA
                </Typography>
                <div className={classes.tableContainer}>
                    <DeliveredToAgent />
                </div>
            </div>
        );

        const ReturnFromAgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ chuyển hoàn Hub
                </Typography>
                <div className={classes.tableContainer}>
                    <ReturnFromAgent />
                </div>
            </div>
        );

        const CardCancelledArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thẻ đã hủy
                </Typography>
                <div className={classes.tableContainer}>
                    <CardCancelled />
                </div>
            </div>
        );

        const CardKPIArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Đặt KPI cho Agent Thẻ
                </Typography>
                <div className={classes.tableContainer}>
                    <CardKPIList />
                </div>
            </div>
        );

        const AgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Danh sách DSA
                </Typography>
                <div className={classes.tableContainer}>{/* <AgentList /> */}</div>
            </div>
        );

        const AgentLocationArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Phân quyền địa bàn
                </Typography>
                <div className={classes.tableContainer}>{/* <AgentLocation /> */}</div>
            </div>
        );
        const AgentProductArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Phân quyền sản phẩm
                </Typography>
                <div className={classes.tableContainer}>{/* <AgentProduct /> */}</div>
            </div>
        );

        const OPAllocationArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Phân bổ cho OP
                </Typography>
                <div className={classes.tableContainer}>{/* <OPAllocationList /> */}</div>
            </div>
        );

        var OneSignal = window.OneSignal || [];
        var that = this;
        //if (OneSignal) {
        console.log('ONESIGNAL HERE!');
        OneSignal.push(function () {
            OneSignal.on('subscriptionChange', function (isSubscribed) {
                if (isSubscribed) that.postOneSignalPlayerIdToServer();
            });

            // OneSignal.init({
            //     appId: '45e4242a-2970-41e5-8ce8-cdcbe29ec387'
            // });
            OneSignal.init({
                appId: "5dec764a-8795-4628-88ae-587365d3330c",
            });
        });

        this.postOneSignalPlayerIdToServer();
        //}
        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="absolute"
                        className={classNames(
                            classes.appBar,
                            this.state.open && classes.appBarShift
                        )}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden
                                )}
                            >
                                <MenuIcon />
                            </IconButton>
                            {!isCardHubAcc() || isCardBankAcc() ? (
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                >
                                    Quản trị thẻ tại Bank
                                </Typography>
                            ) : (
                                    <Typography
                                        variant="h5"
                                        color="inherit"
                                        noWrap
                                        className={classes.title}
                                    >
                                        Quản trị thẻ tại Hub
                                    </Typography>
                                )}

                            {this.state.user && this.state.user.order_importable ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        iconStyle={{
                                            height: 200,
                                            width: 200,
                                            fontSize: '48px'
                                        }}
                                        aria-label="Import hồ sơ mới"
                                        onClick={this.handleShowImportOrder}
                                    >
                                        <Icon>cloud_upload</Icon>
                                        <span style={{ marginLeft: 10 }}>Import</span>
                                    </Button>
                                </div>
                            ) : (
                                    ''
                                )}

                            {this.state.user && this.state.user.order_createable ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        iconStyle={{
                                            height: 200,
                                            width: 200,
                                            fontSize: '48px'
                                        }}
                                        aria-label="Tạo hồ sơ mới"
                                        onClick={this.handleShowCreateOrder}
                                    >
                                        <Icon>create_new_folder</Icon>
                                        <span style={{ marginLeft: 10 }}>Tạo mới</span>
                                    </Button>
                                </div>
                            ) : (
                                    ''
                                )}

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đổi mật khẩu"
                                onClick={this.handleShowChangePassword}
                            >
                                <Icon>vpn_key</Icon>
                                <span style={{ marginLeft: 10 }}>Đổi mật khẩu</span>
                            </Button>

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đăng xuất"
                                onClick={this.handleLogOut}
                            >
                                <Icon>exit_to_app</Icon>
                                <span style={{ marginLeft: 10 }}>Đăng xuất</span>
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !this.state.open && classes.drawerPaperClose
                            )
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarHeader}>
                            {cs.System_Id === 1 ? (
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../img/logo-app-trans.png')}
                                />
                            ) : (
                                    <Avatar
                                        className={classes.avatar}
                                        src={require('../img/logo-small.png')}
                                    />
                                )}
                            <Typography variant="body" className={classes.username}>
                                {username}
                            </Typography>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>{mainListItems}</List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Route exact path="/" component={DailyDashboardArea} />
                        <Route path="/report/daily" component={DailyDashboardArea} />
                        <Route path="/report/monthly" component={MonthlyDashboardArea} />
                        <Route path="/waitPrinting" component={WaitPrintingArea} />
                        <Route path="/finishPrinting" component={FinishPrintingArea} />

                        <Route path="/deliveryToHub" component={DeliveryToHubsArea} />
                        <Route path="/returnFromHub" component={ReturnFromHubArea} />
                        <Route path="/customerReceived" component={CustomerReceivedArea} />
                        <Route path="/activated" component={ActivatedArea} />
                        <Route path="/receivedFromBank" component={ReceivedFromBankArea} />
                        <Route path="/onDeliveryToAgent" component={OnDeliveryToAgentArea} />
                        <Route path="/deliveredToAgent" component={DeliveredToAgentArea} />
                        <Route path="/returnFromAgent" component={ReturnFromAgentArea} />
                        <Route path="/cardCancelled" component={CardCancelledArea} />
                        <Route path="/kpi" component={CardKPIArea} />

                        <Route path="/detail/:orderCode" component={OrderDetail} />
                        <Route path="/onDeliveryToAgent/agents/:id" component={SuggestAgents} />

                        {/* <Route path="/agent" component={AgentArea} />
                        <Route path="/agent_location" component={AgentLocationArea} />
                        <Route path="/agent_product" component={AgentProductArea} />
                        <Route path="/op_allocation" component={OPAllocationArea} /> */}

                        <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                            {/* <CreateOrder
                                open={this.state.showCreateOrder}
                                onClose={this.handleCloseCreateOrder}
                            />
                            <ImportOrder
                                open={this.state.showImportOrder}
                                onClose={this.handleCloseImportOrder}
                            /> */}
                            <ChangePassword
                                open={this.state.showChangePassword}
                                onClose={this.handleCloseChangePassword}
                            />
                        </ToastProvider>
                    </main>
                </div>
            </Router>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
