import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Legend
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';

const productsURL = cs.BaseURL + '/product/list';
const addressStatesURL = cs.BaseURL + '/address/states';
const URL = cs.BaseURL + '/report/daily_summary';

function formatGridValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY');
    if (value.created_qty == 0 || value.created_qty == '0') value.created_qty = '-';
    if (value.canceled_qty == 0 || value.canceled_qty == '0') value.canceled_qty = '-';
    if (value.agent_open_qty == 0 || value.agent_open_qty == '0') value.agent_open_qty = '-';
    if (value.agent_assigned_qty == 0 || value.agent_assigned_qty == '0')
        value.agent_assigned_qty = '-';
    if (value.agent_submitted_qty == 0 || value.agent_submitted_qty == '0')
        value.agent_submitted_qty = '-';
    if (value.agent_close_qty == 0 || value.agent_close_qty == '0') value.agent_close_qty = '-';
    if (value.op_approved_qty == 0 || value.op_approved_qty == '0') value.op_approved_qty = '-';
    if (value.op_rejected_qty == 0 || value.op_rejected_qty == '0') value.op_rejected_qty = '-';
    if (value.mc_approved_qty == 0 || value.mc_approved_qty == '0') value.mc_approved_qty = '-';
    if (value.mc_rejected_qty == 0 || value.mc_rejected_qty == '0') value.mc_rejected_qty = '-';
    return value;
}

function formatChartValue(value) {
    if (value && value.report_date)
        value.report_date = moment.unix(value.report_date).tz('Asia/Ho_Chi_Minh').format('DD/MM');
    return value;
}

class DailySummaryByDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agentAdrStateCode: [],
            merchants: [],
            products: [],
            chartData: [],

            grid1Data: [],
            grid1Columns: [
                { name: 'report_date', title: 'Ngày' },
                // { name: 'mc_code', title: 'Đối tác' },
                { name: 'mc_product_name', title: 'Sản phẩm' },
                { name: 'agent_open_qty', title: 'Tồn đầu' },
                { name: 'agent_assigned_qty', title: 'Phân công' },
                { name: 'agent_submitted_qty', title: 'Hoàn thành' },
                { name: 'op_approved_qty', title: 'Được duyệt' },
                { name: 'agent_close_qty', title: 'Tồn cuối' }
            ],
            grid1ColumnConfig: [
                { columnName: 'report_date', align: 'center', width: 110 },
                // { columnName: 'mc_code', align: 'center', width: 200 },
                { columnName: 'mc_product_name', align: 'left' },
                { columnName: 'agent_open_qty', align: 'center', width: 140 },
                { columnName: 'agent_assigned_qty', align: 'center', width: 140 },
                { columnName: 'agent_submitted_qty', align: 'center', width: 140 },
                { columnName: 'op_approved_qty', align: 'center', width: 140 },
                { columnName: 'agent_close_qty', align: 'center', width: 140 }
            ],
            grid1TotalCount: 0,
            grid1CurrentPage: 0,

            grid2Data: [],
            grid2Columns: [
                { name: 'report_date', title: 'Ngày' },
                { name: 'card_wait_print_qty', title: 'Thẻ đã phát hành, chờ in' },
                { name: 'card_finish_print_qty', title: 'Thẻ đã in và chờ Hub nhập kho' },
                {
                    name: 'card_hub_delivered_qty',
                    title: 'Thẻ đã nhập kho Hub, chờ trả cho khách hàng'
                },
                {
                    name: 'card_agent_on_delivery_qty',
                    title: 'Thẻ đã xuất kho và chuyển phát đến RM/DSA'
                },
                { name: 'card_agent_delivered_qty', title: 'Thẻ đã bàn giao cho RM/DSA' },
                { name: 'card_agent_on_return_qty', title: 'Thẻ đang chuyển hoàn Hub' },
                { name: 'card_agent_returned_qty', title: 'Thẻ đã chuyền hoàn Hub' },
                { name: 'card_hub_returned_qty', title: 'Thẻ chuyển hoàn Bank' },
                { name: 'card_cancelled_qty', title: 'Thẻ đã hủy' },
                { name: 'card_customer_received_qty', title: 'Thẻ đã đến tay khách hàng' },
                { name: 'card_activated_qty', title: 'Thẻ được kích hoạt, sẵn sàng hoạt động' }
            ],
            grid2ColumnConfig: [
                { columnName: 'report_date', align: 'center', width: 110 },
                { columnName: 'card_wait_print_qty', align: 'center', width: 200 },
                {
                    columnName: 'card_finish_print_qty',
                    align: 'center',
                    width: 250
                },
                {
                    columnName: 'card_hub_delivered_qty',
                    align: 'center',
                    width: 350,
                    wordWrapEnabled: true
                },
                {
                    columnName: 'card_agent_on_delivery_qty',
                    align: 'center',
                    width: 350,
                    wordWrapEnabled: true
                },
                {
                    columnName: 'card_agent_delivered_qty',
                    align: 'center',
                    width: 250
                },
                {
                    columnName: 'card_agent_on_return_qty',
                    align: 'center',
                    width: 250
                },
                {
                    columnName: 'card_agent_returned_qty',
                    align: 'center',
                    width: 250
                },
                {
                    columnName: 'card_hub_returned_qty',
                    align: 'center',
                    width: 250
                },
                {
                    columnName: 'card_cancelled_qty',
                    align: 'center'
                },
                {
                    columnName: 'card_customer_received_qty',
                    align: 'center',
                    width: 200
                },
                {
                    columnName: 'card_activated_qty',
                    align: 'center',
                    width: 350,
                    wordWrapEnabled: true
                }
            ],
            grid2TotalCount: 0,
            grid2CurrentPage: 0,

            pageSize: 15,
            loading: true,
            search_date_start: moment()
                .tz('Asia/Ho_Chi_Minh')
                .subtract(14, 'day')
                .startOf('day')
                .format('YYYY-MM-DD'),

            search_date_end: moment().tz('Asia/Ho_Chi_Minh').endOf('day').format('YYYY-MM-DD')
        };
        this.changeGrid1CurrentPage = this.changeGrid1CurrentPage.bind(this);
        this.changeGrid2CurrentPage = this.changeGrid2CurrentPage.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.loadAddressStates = this.loadAddressStates.bind(this);
        // this.loadProducts();
        this.loadAddressStates();
    }

    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    loadProducts() {
        let queryString = `${productsURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    var merchants = [];
                    merchants.push({ code: null, name: 'Tất cả' });
                    data.data.map((p) => {
                        const found = merchants.some((el) => el.code === p.mc_code);
                        if (found) return;
                        merchants.push({ code: p.mc_code, name: p.mc_code });
                    });

                    var products = [];
                    products.push({ code: null, name: 'Tất cả' });
                    data.data.map((p) => {
                        const found = products.some((el) => el.code === p.code);
                        if (found) return;
                        products.push(p);
                    });

                    this.setState({
                        products: products,
                        merchants: merchants
                    });
                } else {
                    this.setState({
                        products: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    loadAddressStates() {
        let queryString = `${addressStatesURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    var agentAdrStateCode = [];
                    agentAdrStateCode.push({ code: null, name: '' });
                    data.data.map((s) => {
                        const found = agentAdrStateCode.some((el) => el.code === s.state_code);
                        if (found) return;
                        agentAdrStateCode.push({ code: s.state_code, name: s.state_name });
                    });

                    this.setState({
                        agentAdrStateCode: agentAdrStateCode
                    });
                } else {
                    this.setState({
                        agentAdrStateCode: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    agentAdrStateCode: []
                });
            });
    }

    changeGrid1CurrentPage(page) {
        this.setState(
            {
                loading: true,
                grid1CurrentPage: page
            },
            () => {
                this.loadGrid1Data();
            }
        );
    }
    changeGrid2CurrentPage(page) {
        this.setState(
            {
                loading: true,
                grid2CurrentPage: page
            },
            () => {
                this.loadGrid2Data();
            }
        );
    }
    queryChartString() {
        const {
            search_date_start,
            search_date_end,
            searchTeam,
            searchProduct,
            searchMerchant
        } = this.state;

        let queryString = `${URL}?new=true&orderby=report_date`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchTeam) queryString = `${queryString}&team_name=${searchTeam}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadChartData = (conditions) => {
        let sum_card_activated_qty = 0;
        let sum_card_agent_delivered_qty = 0;
        let sum_card_agent_on_delivery_qty = 0;
        let sum_card_agent_on_return_qty = 0;
        let sum_card_agent_returned_qty = 0;
        let sum_card_cancelled_qty = 0;
        let sum_card_customer_received_qty = 0;
        let sum_card_finish_print_qty = 0;
        let sum_card_hub_delivered_qty = 0;
        let sum_card_hub_returned_qty = 0;
        let sum_card_wait_print_qty = 0;

        const queryString = this.queryChartString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    console.log(data.data);
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatChartValue(data.data[i]);
                        sum_card_activated_qty += parseInt(data.data[i].card_activated_qty);
                        sum_card_agent_delivered_qty += parseInt(
                            data.data[i].card_agent_delivered_qty
                        );
                        sum_card_agent_on_delivery_qty += parseInt(
                            data.data[i].card_agent_on_delivery_qty
                        );
                        sum_card_agent_on_return_qty += parseInt(
                            data.data[i].card_agent_on_return_qty
                        );
                        sum_card_agent_returned_qty += parseInt(
                            data.data[i].card_agent_returned_qty
                        );
                        sum_card_cancelled_qty += parseInt(data.data[i].card_cancelled_qty);
                        sum_card_customer_received_qty += parseInt(
                            data.data[i].card_customer_received_qty
                        );
                        sum_card_finish_print_qty += parseInt(data.data[i].card_finish_print_qty);
                        sum_card_hub_delivered_qty += parseInt(data.data[i].card_hub_delivered_qty);
                        sum_card_hub_returned_qty += parseInt(data.data[i].card_hub_returned_qty);
                        sum_card_wait_print_qty += parseInt(data.data[i].card_wait_print_qty);
                    }

                    this.setState({
                        chartData: [
                            {
                                card_activated_qty: sum_card_activated_qty,
                                card_agent_delivered_qty: sum_card_agent_delivered_qty,
                                card_agent_on_delivery_qty: sum_card_agent_on_delivery_qty,
                                card_agent_on_return_qty: sum_card_agent_on_return_qty,
                                card_agent_returned_qty: sum_card_agent_returned_qty,
                                card_cancelled_qty: sum_card_cancelled_qty,
                                card_customer_received_qty: sum_card_customer_received_qty,
                                card_finish_print_qty: sum_card_finish_print_qty,
                                card_hub_delivered_qty: sum_card_hub_delivered_qty,
                                card_hub_returned_qty: sum_card_hub_returned_qty,
                                card_wait_print_qty: sum_card_wait_print_qty,
                                report_date:
                                    this.state.search_date_start == this.state.search_date_end
                                        ? this.state.search_date_start
                                        : 'từ ' +
                                          this.state.search_date_start +
                                          ' đến ' +
                                          this.state.search_date_end
                            }
                        ],
                        loading: false
                    });
                    console.log(this.state.chartData);
                } else {
                    this.setState({
                        chartData: [],
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    queryGrid1String() {
        const {
            pageSize,
            grid1CurrentPage,
            search_date_start,
            search_date_end,
            searchTeam,
            searchProduct,
            searchMerchant
        } = this.state;
        //pagination error - will fix later
        let queryString = `${URL}?pagesize=${pageSize}&page=${
            grid1CurrentPage + 1
        }&orderby=report_date&asc=false&group_field1=mc_product_code&new=true`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchTeam) queryString = `${queryString}&team_name=${searchTeam}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadGrid1Data = (conditions) => {
        const queryString = this.queryGrid1String();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                    }

                    this.setState({
                        grid1Data: data.data,
                        grid1TotalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        grid1Data: [],
                        grid1TotalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    queryGrid2String() {
        const {
            pageSize,
            grid2CurrentPage,
            search_date_start,
            search_date_end,
            searchTeam,
            searchProduct,
            searchMerchant,
            searchAgentAdrStateCode,
            searchHubRegionId,
            searchAgentPhone
        } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${
            grid2CurrentPage + 1
        }&orderby=report_date&asc=false&group_field1=mc_product_code&group_field2=team_code&new=true`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchTeam) queryString = `${queryString}&team_name=${searchTeam}`;

        if (searchAgentAdrStateCode)
            queryString = `${queryString}&agent_adr_state_code=${searchAgentAdrStateCode}`;
        if (searchHubRegionId) queryString = `${queryString}&hub_region_id=${searchHubRegionId}`;
        if (searchAgentPhone) queryString = `${queryString}&agent_phone=${searchAgentPhone}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadGrid2Data = (conditions) => {
        const queryString = this.queryGrid2String();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                    }

                    this.setState({
                        grid2Data: data.data,
                        grid2TotalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        grid2Data: [],
                        grid2TotalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    loadData = (conditions) => {
        this.loadChartData();
        // this.loadGrid1Data();
        this.loadGrid2Data();
    };
    handleChange = (name) => (event) => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            chartData,
            grid1Data,
            grid1Columns,
            grid1ColumnConfig,
            grid1CurrentPage,
            grid1TotalCount,

            grid2Data,
            grid2Columns,
            grid2ColumnConfig,
            grid2CurrentPage,
            grid2TotalCount,

            pageSize,
            loading
        } = this.state;

        return (
            <div>
                <form className={classes.search_container} noValidate autoComplete="off">
                    <TextField
                        id="search_date_start"
                        label="Từ ngày"
                        className={classes.search_field}
                        value={this.state.search_date_start}
                        onChange={this.handleChange('search_date_start')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                    <TextField
                        id="search_date_end"
                        label="Đến ngày"
                        className={classes.search_field}
                        value={this.state.search_date_end}
                        onChange={this.handleChange('search_date_end')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <FormControl className={classes.search_field}>
                        <InputLabel htmlFor="agentAdrStateCodeId">Tỉnh thành</InputLabel>
                        <Select
                            value={this.state.searchAgentAdrStateCode}
                            onChange={this.handleChange('searchAgentAdrStateCode')}
                            inputProps={{
                                name: 'agentAdrStateCode',
                                id: 'agentAdrStateCodeId'
                            }}
                        >
                            {this.state.agentAdrStateCode.map((item) => (
                                <MenuItem value={item.code}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* <FormControl className={classes.search_product_field}>
                        <InputLabel htmlFor="productId">Sản phẩm</InputLabel>
                        <Select
                            value={this.state.searchProduct}
                            onChange={this.handleChange('searchProduct')}
                            inputProps={{
                                name: 'product',
                                id: 'productId'
                            }}
                        >
                            {this.state.products.map((pro) => (
                                <MenuItem value={pro.code}>
                                    {pro.code && (
                                        <div>
                                            {pro.mc_code} - {pro.name}
                                        </div>
                                    )}
                                    {!pro.code && <div>{pro.name}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}

                    <TextField
                        id="searchTeam"
                        label="Hub"
                        className={classes.search_field}
                        value={this.state.searchHubRegionId}
                        onChange={this.handleChange('searchHubRegionId')}
                        type="text"
                    />

                    <TextField
                        id="searchTeam"
                        label="SĐT nhân viên"
                        className={classes.search_field}
                        value={this.state.searchAgentPhone}
                        onChange={this.handleChange('searchAgentPhone')}
                        type="text"
                    />
                </form>

                <Chart data={chartData} className={classes.chart} height="300">
                    <ArgumentAxis />
                    <ArgumentScale />
                    <ValueAxis />
                    <Legend
                        position="bottom"
                        rootComponent={LegendRoot}
                        labelComponent={LegendLabel}
                        // itemComponent={Item}
                        // labelComponent={Label}
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_wait_print_qty"
                        name="Thẻ đã phát hành, chờ in"
                        color="#FF0000"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_finish_print_qty"
                        name="Thẻ đã in và chờ Hub nhập kho"
                        color="#FFC0CB"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_hub_delivered_qty"
                        name="Thẻ đã nhập kho Hub, chờ trả cho khách hàng"
                        color="#FFFF00"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_agent_on_delivery_qty"
                        name="Thẻ đã xuất kho và chuyển phát đến RM/DSA"
                        color="#EE82EE"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_agent_delivered_qty"
                        name="Thẻ đã bàn giao cho RM/DSA"
                        color="#00FF00"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_agent_on_return_qty"
                        name="Thẻ đang chuyển hoàn Hub"
                        color="#00FFFF"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_agent_returned_qty"
                        name="Thẻ đã chuyển hoàn Hub"
                        color="#A52A2A"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_hub_returned_qty"
                        name="Thẻ chuyển hoàn Bank"
                        color="#00BFFF"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_cancelled_qty"
                        name="Thẻ đã hủy"
                        color="#008000"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_customer_received_qty"
                        name="Thẻ đã đến tay khách hàng"
                        color="#1ABC9C"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="card_activated_qty"
                        name="Thẻ được kích hoạt, sẵn sàng hoạt động"
                        color="#F39C12"
                    />
                    <Stack />
                </Chart>

                {/* <Paper className={classes.grid_Container}>
                    <Grid rows={grid1Data} columns={grid1Columns} getRowId={getGrid1RowId}>
                        <PagingState
                            currentPage={grid1CurrentPage}
                            onCurrentPageChange={this.changeGrid1CurrentPage}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={grid1TotalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={grid1ColumnConfig}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
                <Typography
                    variant="h5"
                    gutterBottom
                    component="h2"
                    style={{ marginTop: 30, marginLeft: 10 }}
                >
                    Chi tiết theo Team
                </Typography> */}
                <Paper className={classes.grid_Container}>
                    <Grid rows={grid2Data} columns={grid2Columns} getRowId={getGrid2RowId}>
                        <PagingState
                            currentPage={grid2CurrentPage}
                            onCurrentPageChange={this.changeGrid2CurrentPage}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={grid2TotalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={grid2ColumnConfig}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const rootStyles = {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    margin: 'auto',
    flexDirection: 'row'
};
const LegendRoot = (props) => <Legend.Root {...props} style={rootStyles} />;

const defaultLabelStyles = {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    fontSize: '14px'
};
const LegendLabel = (props) => (
    <div {...props} style={defaultLabelStyles}>
        {props.text}
    </div>
);

const Item = withStyles(styles)(({ classes, ...restProps }) => (
    <Legend.Item {...restProps} className={classes.item} />
));

const Label = withStyles(styles)(({ classes, ...restProps }) => (
    <Legend.Label {...restProps} className={classes.label} />
));

const getGrid1RowId = (row) => row.report_date + row.mc_product_code;
const getGrid2RowId = (row) => row.report_date + row.mc_product_code + row.team_code;
const getRowId = (row) => row.id;
DailySummaryByDate.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = (theme) => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_product_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 400,
        minWidth: 300
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    },
    chart: {
        marginTop: 30
    },
    item: {
        flexDirection: 'row-reverse'
    },
    label: {
        textAlign: 'right'
    }
});

const RowComponent = (props) => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = (props) => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = (props) => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => (
    <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withStyles(styles)(DailySummaryByDate);
