import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withToastManager } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';

import cs from '../../../const.js';
// import CreateAgentProduct from './CreateAgentProduct';
// import DeleteAgentProduct from './DeleteAgentProduct';

const cardURL = cs.BaseURL + '/card/list';
const URL = cs.BaseURL + '/card/suggest_agent';
const assignURL = cs.BaseURL + '/card/assign-agent';

function formatValue(value) {
    return value;
}

class SuggestAgents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            order_code: '',
            columns: [
                { name: 'name', title: 'Agent' },
                { name: 'phone', title: 'Phone' },
                { name: 'code', title: 'Code' },
                { name: 'sub_region', title: 'Sub Region' },
                { name: 'doing_order_qty', title: 'Order Doing' },
                { name: 'action', title: ' ' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'name', align: 'center', width: 250 },
                { columnName: 'phone', align: 'center', width: 100 },
                { columnName: 'code', align: 'center', width: 200 },
                { columnName: 'sub_region', align: 'center', width: 150 },
                { columnName: 'doing_order_qty', align: 'center', width: 120 },
                { columnName: 'action', algin: 'center', width: 90 }
            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 15,
            currentPage: 0,
            sorting: [{ columnName: 'doing_order_qty', direction: 'desc' }],
            loading: true
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadCard = this.loadCard.bind(this);
    }
    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    componentDidMount() {
        this.loadCard();
    }

    loadCard() {
        this.setState({ loading: true });
        const queryString = `${cardURL}?new=true&id=${this.state.id}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-card-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        if (data.data[i].id == this.state.id) {
                            this.setState({
                                order_code: data.data[i].order_code,
                                loading: false
                            });
                            this.loadData(data.data[i].order_code);
                        }
                    }
                } else {
                    this.setState({
                        order_code: '',
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    queryString() {
        const { order_code, pageSize, currentPage, sorting } = this.state;

        let queryString = `${URL}?new=true&order_code=${order_code}&pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }

    loadData(order_code) {
        this.setState({ loading: true });
        let queryString = `${URL}`;
        //const queryString = this.queryString();
        if (order_code != undefined)
            fetch(queryString, {
                method: 'POST',
                body: JSON.stringify({
                    order_code: order_code
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem(cs.System_Code + '-card-token')
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log(this.state.order_code)
                    if (data && data.data) {
                        for (var i = 0; i < data.data.length; i++) {
                            data.data[i] = formatValue(data.data[i]);
                        }

                        this.setState({
                            rows: data.data,
                            totalCount: data.total_count,
                            loading: false
                        });
                    } else {
                        this.setState({
                            rows: [],
                            totalCount: 0,
                            loading: false
                        });
                        if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                            sessionStorage.clear();
                            localStorage.clear();
                            window.location.reload();
                        }
                    }
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
    }

    handleDialogDisAgree = () => {
        this.props.history.push({
            pathname: '/onDeliveryToAgent',
            state: { reload: true }
        });
    };

    handleAssignClick = props => {
        console.log(props)
        if (window.confirm('Assign card to agent ' + props.name))
            fetch(assignURL, {
                method: 'POST',
                body: JSON.stringify({
                    agent_id: props.id,
                    agent_phone: props.phone,
                    order_code: this.state.order_code
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: localStorage.getItem(cs.System_Code + '-card-token')
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        window.location.reload();
                    }

                    if (data && data.error && data.code != cs.Succeed) {
                        window.alert(JSON.stringify(data.error));
                        //don't know why it don't work 
                        /*this.props.toastManager.add(JSON.stringify(data.error), {
                            appearance: 'error',
                            autoDismiss: true,
                            pauseOnHover: true
                        });*/
                    } else {
                        window.alert('Agent is assign !');
                        /*this.props.toastManager.add('Agent is assign !', {
                            appearance: 'success',
                            autoDismiss: true,
                            pauseOnHover: true
                        });*/
                    }
                    console.log('here');
                    this.props.history.push({
                        pathname: '/onDeliveryToAgent',
                        state: { reload: true }
                    });
                })
                .catch(() => {
                    //this.setState({ loading: false });
                });
    }

    CellComponent = props => {
        const { column } = props;
        if (column.name === 'action') {
            return (
                <Table.Cell
                    {...props}
                    style={{
                        padding: 2,
                    }}>
                    {this.ActionCell(props)}
                </Table.Cell>
            )
        }

        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2
                }}
            />
        );
    };

    ActionCell = props => {
        return (
            <span>
                <Button
                    color="primary"
                    onClick={() => this.handleAssignClick(props.row)}
                >
                    <Icon>assignment_ind</Icon>
                </Button>
            </span>
        );
    }

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" className={classes.dialog}>
                    <DialogTitle id="edit_agent_form-dialog-title">{this.state.order_code} - Agents</DialogTitle>
                    <DialogContent>
                        <Paper className={classes.grid_Container}>
                            <Grid rows={rows} columns={columns} getRowId={getRowId}>
                                <SortingState sorting={sorting} onSortingChange={this.changeSorting} />
                                <PagingState
                                    currentPage={currentPage}
                                    onCurrentPageChange={this.changeCurrentPage}
                                    onPageSizeChange={this.changePageSize}
                                    pageSize={pageSize}
                                />
                                <CustomPaging totalCount={totalCount} />

                                <VirtualTable
                                    tableComponent={TableComponent}
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={this.CellComponent}
                                    rowComponent={RowComponent}
                                    messages={tableMessages}
                                />
                                <TableHeaderRow
                                    showSortingControls
                                    className={classes.grid_header}
                                    rowComponent={HeaderRowComponent}
                                    cellComponent={HeaderCellComponent}
                                />
                                <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                            </Grid>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisAgree} color="primary">
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const getRowId = row => row.id;
SuggestAgents.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    dialog: {
        width: '100%'
    }
});

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                // paddingLeft:40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có nhân viên nào'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withToastManager(withStyles(styles)(SuggestAgents));
